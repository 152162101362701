import OfferResultsItem from "./OfferResultItem";
import { useState } from 'react';
import nutricion from "./img/nutricion.webp";
import "./style-trainer.css";
import Loader from "../../../Loader";

export default function OfferResults({results, isSearching, tiposervicio}) {

    const [offerText, setOfferText] = useState(tiposervicio);
    let divBox;
    
    if (offerText === "NUTRICION") {
        divBox = <div className="showcase" >
            <img src={nutricion} alt="Nutricion" className="offer-nutri"></img>
            {/* <h1 className="offer-title"></h1> */}
            </div>; 
    };
    if (offerText === "PERSONAL_TRAINER") {
        divBox = <div className="offer-train" ></div>; 
    };
    if (offerText === "PAQUETE_INTEGRAL") {
        divBox = <div className="offer-paq" ></div>; 
    };        
if (isSearching) {
    return(<Loader/>);
}
else
{
    return (
        <>
        {divBox}
        <div>
        <label htmlFor="Offers"></label>
            <select name="OfferType" id="offertype-trainer"
            value={offerText}
            onChange={({target: { value }}) => setOfferText(value.toString())}          
            >
            <option value='NUTRICION' selected>
                {/* NUTRICIÓN */}
                NUTRITION
            </option>
            <option value="PERSONAL_TRAINER">
                {/* PERSONAL TRAINER */}
                PERSONAL TRAINER
            </option>
            <option value="PAQUETE_INTEGRAL">
                {/* PAQUETE INTEGRAL */}
                FULL PACKAGE
            </option>
        </select>
        </div>
        <div>
            {results?.filter((value) => value.TrainingArea.includes(offerText) && value.OfferStatus.includes("validated")).map((value)=>
            <OfferResultsItem key={value.TrainingArea+"|"+value.UserOfferId} {...value} />)} 
        </div>
        </>
    );
}


}