import { useState, useContext } from 'react';
import AuthContext from "../../../../context/authorizer";
//import { useNavigate  } from "react-router-dom";
import "./val-prof.css";
//import pdfviewer from  'pdf-viewer-reactjs';
import PdfDoc from  "../../../../pdfviewer";
//import { PDFViewer, Text } from '@react-pdf/renderer';

var apigClientFactory = require('aws-api-gateway-client').default;

var configAttach = {
            invokeUrl: 'https://r1ad1i0bo1.execute-api.us-east-1.amazonaws.com/PDN/',
            defaultContentType: 'application/pdf'
          };
          
var apigClientAttach = apigClientFactory.newClient(configAttach);

var pathParams = {
    //This is where path request params go.
    //userId: '1234',
  };
  var pathTemplateAttach = '/avhealthattachs3folderresource/{bucket}/{folder}/{filename}';  
  var methodGet = 'GET';     

export default function AttachmentList({value,user} ) {
    const { auth } = useContext(AuthContext);
    const [document, setDocument] = useState(null);        
    var divbox; 
    var buttondoc; 

    const convertirbase64Text = (archivo) => {
        const linkSource = `data:application/pdf;base64,${archivo}`;
        var url = linkSource;
        fetch(url)
        .then((response) => response.blob())
        .then((data) =>  setDocument(data));
    }   

    const handleCleanDocClick = (e) => {
        setDocument(null);        
    }
    
    const handleDownloadDocClick = (e) => {
        pathParams = {
            //This is where path request params go.
            bucket: 'attach-avhealthfitness',
            folder: user,
            filename: value 
          };
          var additionalParams = {headers: {Authorization: auth.tkn}}; 
          apigClientAttach.invokeApi(pathParams, pathTemplateAttach, methodGet, additionalParams, null)
           .then(function(result){
               //This is where you would put a success callback
               convertirbase64Text(result.data);          
           }).catch( function(result){
               //This is where you would put an error callback
           });       
      }    
    if (document===null){
        divbox = <div></div>;
        buttondoc = <button type="button" className="btn-validar-profesional" onClick={handleDownloadDocClick}>
            {/* Ver */}
            View
        </button>;    
    }
    else {
       // divbox = <div><PdfViewer Docpdf={document} height="100px" /></div>; 
       divbox = <div><PdfDoc document={document}/></div>;
       buttondoc = <button type="button" className="btn-validar-profesional" onClick={handleCleanDocClick}>
            {/* Cerrar */}
            Close
        </button>;                
    }
  
    return (
        <div className="validar-profesional">
            <li>
                <h2>
                    {value}
                </h2>
            </li>
            {divbox}
            <div>{buttondoc}</div>                
        </div>
    );
}