import Routing from "./routing";
import AuthProvider from "./context/authorizer/AuthProvider";

function App() {
  return (
   <AuthProvider>
    <Routing />
   </AuthProvider>
  );
}

export default App;