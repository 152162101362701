import { useContext,useState } from "react";
import AuthContext from "../../../../context/authorizer";

var apigClientFactory = require('aws-api-gateway-client').default;
var config = {
            invokeUrl: 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN'
          };
     
var apigClient = apigClientFactory.newClient(config);

var pathParams = {
  //This is where path request params go.
  //userId: '1234',
};
var pathGetParams = {
  //This is where path request params go.
  //userId: '1234',
};
// Template syntax follows url-template https://www.npmjs.com/package/url-template
var pathTemplate = '/avupdateappliedofferresource';
var pathSendTemplate = '/avhealthsendnotificationresource';    
var method = 'POST';
var pathGetTemplate = '/avhealthprofprofileresource/{professionalId}';  
var getmethod = 'GET';

export default function UpdateAppliedOffer(OfferData) {
    const { auth } = useContext(AuthContext); 
    const [success,setSuccess] = useState(false);        
 
    const handleUpdateOfferClick = (e) => {
    console.log("Clicked!!!");

    pathGetParams = {professionalId: OfferData.results.ProfessionalId};


    var body = {
            ProfessionalId: OfferData.results.ProfessionalId,          
            Timestamp: OfferData.results.Timestamp,
            OfferStatus: "ready-to-train"
            };
  
    var additionalParams = {headers: {Authorization: auth.tkn}}; 
    var profname;
    var profphone;

    apigClient.invokeApi(pathGetParams, pathGetTemplate, getmethod, additionalParams, null)
    .then(function(result){
    //This is where you would put a success callback
    if (result.headers['content-length'] === '2')
    {
        alert(
          // "Profesional no tiene los datos Actualizados."
          "The professional's information is not updated."
        );    
    }
    profname= result.data.Item.BasicData.M.InfoPersonal.M.nombre_completo.S;
    profphone= result.data.Item.BasicData.M.InfoPersonal.M.telefono.S;
    
    apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
    .then(function(result){
        //This is where you would put a success callback
     
        var bodysend = {
          pemail: OfferData.results.ProfessionalId,
          pname: profname,
          pphone: profphone,
          idoferta: OfferData.results.Timestamp,
          uemail: OfferData.results.UserOfferId,          
          trainingarea: OfferData.results.TrainingArea,
          ucity:  OfferData.results.CityLocation,
          uname:  OfferData.results.FullName,
          uphone:  OfferData.results.telefono,
          jobtype:  OfferData.results.JobType,
          PayOffer:  OfferData.results.PayOffer
          };
          //console.log(body);                                     
        apigClient.invokeApi(pathParams, pathSendTemplate, method, additionalParams, bodysend)                                   
        .then(function (result) {
          setSuccess(true);
        }).catch( function(result){
          // Add error callback code here.
        });         
    }).catch( function(result){
        //This is where you would put an error callback
    });

    }).catch( function(result){
    //This is where you would put an error callback
    }); 

      
};
if (success)
{
    return (<>
        <div>
            {/* Pago Aceptado, ahora el Profesional podra iniciar el entrenamiento. */}
            Payment Accepted, the professional can now start the training.
        </div>  
        <button onClick={OfferData.close} className="btn-aceptar">
          {/* CERRAR */}
          CLOSE
        </button>         
        </>)
}
else
{ 
return (<>
          <div>
            {/* USUARIO: <h3> { OfferData.results.UserOfferId}</h3>
            NOMBRE: <h3> { OfferData.results.FullName}</h3>
            TELEFONO: <h3> { OfferData.results.telefono}</h3> 
            MODALIDAD: <h3> {OfferData.results.tipo_servicio}</h3>
            SERVICIO: <h3>{OfferData.results.clase_servicio}</h3>
            VR PROF: <h3>{OfferData.results.PayOffer}</h3>
            VR FULL: <h3>{OfferData.results.vr_oferta}</h3> */}

            USER: <h3> { OfferData.results.UserOfferId}</h3>
            NAME: <h3> { OfferData.results.FullName}</h3>
            TELEPHONE: <h3> { OfferData.results.telefono}</h3> 
            MODE: <h3> {OfferData.results.tipo_servicio}</h3>
            SERVICE: <h3>{OfferData.results.clase_servicio}</h3>
            PROF COST: <h3>{OfferData.results.PayOffer}</h3>
            FULL COST: <h3>{OfferData.results.vr_oferta}</h3>                        
          </div>
          <br></br>
          <div>
            {/* UNA VEZ ACEPTES, EL PROFESIONAL INICIARA CON EL ENTRENAMIENTO. */}
            ONCE YOU ACCEPT, THE PROFESSIONAL WILL START THE TRAINING.
          </div>            
          <button onClick={handleUpdateOfferClick} className="btn-aceptar">
            {/* ACEPTAR */}
            ACCEPT
          </button> 
        </>)
}       
};