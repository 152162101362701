import { useState } from 'react';
import "./change-pwd.css";
import NavBar from "../../NavBar";
import { useNavigate  } from "react-router-dom";

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
var poolData = {
         UserPoolId: 'us-east-1_nYrUpgTGj',
         ClientId: '1fbbtser6kh7j5tofdl3uee2h2'
     };
    var userPool = new CognitoUserPool(poolData);

export default function ChangePwd({children }) {
    
    const [userText, setUserText] = useState("");
    const [pwdOldText, setPwdOldText] = useState("");     
    const [pwdText, setPwdText] = useState("");
    const navigate = useNavigate();     

    const handleChangePwdClick = (e) => {

               var username = userText;
               var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                   Username: username,
                   Pool: userPool
               });

               var authenticationData = {
                   Username: userText,
                   Password: pwdOldText
               };
               //console.log(authenticationData);
               var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);        
               //console.log(authenticationDetails);
               //console.log(cognitoUser); 
               cognitoUser.authenticateUser(authenticationDetails, {
                   onSuccess: function () {
                           if (cognitoUser !== null)
                           {
                             cognitoUser.changePassword(pwdOldText, pwdText, function(err, result) {
                                 if (err) {
                                     if ( err.name === "InvalidPasswordException"){
                                        alert(
                                            // "La nueva Contraseña no cumple con los requisitos de minimos de seguridad."
                                            "The new password does not meet the minimum security requirements."
                                        );
                                     } 
                                     return;
                                 }
                                 alert(
                                    // "Contraseña Actualizada Correctamente."
                                    "Password updated successfully."
                                 ); 
                                 navigate('/', { replace: true });                                                            
                             });
                           }
                         },
                       onFailure: function (err) {
                              if ( err.name === "UserNotConfirmedException") 
                              {
                                navigate('/confirm/'+userText, { replace: true });                                    
                              }
                              else if ( err.name === "NotAuthorizedException") 
                              {
                                    alert(
                                        // "La contraseña anterior no es correcta."
                                        "The previous password is incorrect."
                                    );
                              }
                              else
                              {
                               alert(err.message);
                              }              
                             }
                         });

               e.preventDefault();
               console.log('The link was clicked');          
           };
    return (
    <>
    <div className="chg-pwd">
        <div className="container_chg-pwd">
            <div className="form-chg-pwd">
                <div>
                    <h2>Email</h2>
                </div>
                <input id="regusername" 
                type="email" 
                // placeholder="Ingrese su email" 
                placeholder="Enter your email" 
                value={userText}
                onChange={({target: { value }}) => setUserText(value)}    
                />
            </div>
            <div className="form-chg-pwd">
                <div>
                    <h2>
                        {/* Contraseña anterior */}
                        Current password
                    </h2>
                </div>
                <input id="oldpassword" 
                type="password" 
                // placeholder="Ingrese su contraseña anterior"
                placeholder="Enter your current password"
                value={pwdOldText}
                onChange={({target: { value }}) => setPwdOldText(value)}                    
                />
            </div>
            <div className="form-chg-pwd">
                <div>
                    <h2>
                        {/* Nueva contraseña */}
                        New password
                    </h2>
                </div>
                <input id="newpassword" 
                type="password" 
                // placeholder="Ingrese su nueva contraseña" 
                placeholder="Enter your new password" 
                value={pwdText}
                onChange={({target: { value }}) => setPwdText(value)}                  
                />
            </div>
            <div>
                <i>
                    {/* La contraseña debe tener mínimo 8 caracteres, contener al menos una letra mayúscula y una minúscula, un caracter especial y un número. */}
                    The password must have at least 8 characters, include at least one uppercase letter, one lowercase letter, one special character, and one number.
                </i>
            </div>
            <div>
                <button type="button" className="btn-actpwd" onClick={handleChangePwdClick}>
                    {/* Actualizar Contraseña */}
                    Update password
                </button>
            </div>
        </div>
    </div>
    <NavBar />
    </>
     );
}