import { useState, useContext } from 'react';
import AuthContext from "../../../../context/authorizer";
import { useNavigate  } from "react-router-dom";
import "./comp-prof.css";


var apigClientFactory = require('aws-api-gateway-client').default;
var config = {
            invokeUrl: 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN'
          };

var configAttachImg = {
            invokeUrl: 'https://umed42401j.execute-api.us-east-1.amazonaws.com/PDN/',
            defaultContentType: 'image/jpeg'
          };          

var configAttachDoc = {
            invokeUrl: 'https://umed42401j.execute-api.us-east-1.amazonaws.com/PDN/',
            defaultContentType: 'application/pdf'
          };
          
var apigClient = apigClientFactory.newClient(config);
var apigClientAttachImg = apigClientFactory.newClient(configAttachImg);
var apigClientAttachDoc = apigClientFactory.newClient(configAttachDoc);

var pathParams = {
    //This is where path request params go.
    //userId: '1234',
  };
  // Template syntax follows url-template https://www.npmjs.com/package/url-template
  var pathTemplate = '/avhealthprofprofileresource';  
  var pathTemplateAttach = '/avhealthattachs3folderresource/{bucket}/{folder}/{filename}';  
  var method = 'POST';
  var methodPut = 'PUT';

export default function UserProfile(data) {
    
    const { auth, setAuth } = useContext(AuthContext);
    const navigate = useNavigate();         
    const [dirText, setDirText] = useState(""); 
    const [paisText, setPaisText] = useState(""); 
    const [deptoText, setDeptoText] = useState(""); 
    const [muniText, setMuniText] = useState(""); 
    const [fechaText, setFechaText] = useState("");     
    const [esNutriText, setEsNutriText] = useState(false);
    const [esTrainingText, setEsTrainingText] = useState(false);    
    const [descrText, setDescrText] = useState(""); 
    const [laboralText, setLaboralText] = useState("");     
    const [aceptoText, setAceptoText] = useState(false);
    const [imgPrevURL, setImgPrevURL] = useState(null);    
    const [imageURL, setImageURL] = useState(null);
    const [documentURL, setDocumentURL] = useState(null);
    const [listDocuments, setListDocuments] = useState([]);
    const [listArchivos, setListArchivos] = useState("");         

    function validataData(){
        if (dirText==="")
        {
            alert(
                // "Debe ingresar el Campo Direccion"
                "You must enter the Address field."
            );
            return(false);
        }
        if (paisText==="")
        {
            alert(
                // "Debe ingresar el Campo Pais"
                "You must enter the Country field."
            );
            return(false);            
        }
        if (deptoText==="")
        {
            alert(
                // "Debe ingresar el Campo Departamento"
                "You must enter the State/Province field."
            );
            return(false);            
        }
        if (muniText==="")
        {
            alert(
                // "Debe ingresar el Campo Municipio"
                "You must enter the City field."
            );
            return(false);            
        }
        if (fechaText==="")
        {
            alert(
                // "Debe ingresar la fecha de Nacimiento"
                "You must enter the Date of Birth field."
            );
            return(false);            
        }
        if (!esNutriText && !esTrainingText)
        {
            alert(
                // "Debe marcar al Menos alguna de las Opciones (Nutricionista ó Entrenador)"
                "You must select at least one option (Nutritionist or Trainer)."
            );
            return(false);            
        }
        if (descrText==="")
        {
            alert(
                // "Debe diligenciar el Campo Descripcion Personal"
                "You must complete the Personal Description field."
            );
            return(false);            
        }
        if (laboralText==="")
        {
            alert(
                // "Debe diligenciar el Campo Experiencia Laboral"
                "You must complete the Work Experience field."
            );
            return(false);            
        }
        if (listArchivos==="")
        {
            alert(
                // "Debe Adjuntar al menos un documento (Ttulo(s) Obtenidos"
                "You must attach at least one document (Obtained Degree(s))."
            );
            return(false);            
        }
        if (imageURL===null)
        {
            alert(
                // "Debe Adjuntar Foto Personal"
                "You must upload a Personal Photo."
            );
            return(false);            
        }
        return(true);        
      };


    const handleChangeNutrCheckBox = () => {
        setEsNutriText(!esNutriText);
      };

      const handleChangeTrainCheckBox = () => {
        setEsTrainingText(!esTrainingText);
      };


    const handleChangeCheckBox = () => {
        setAceptoText(!aceptoText);
      };

    const convertirbase64 = (archivos) => {
        Array.from(archivos).forEach(archivo => {
            var reader = new FileReader();
            setImgPrevURL(archivo);
            reader.readAsDataURL(archivo);
            reader.onload=function(){
                var arrayAuxiliar=[];
                var base64 = reader.result;
                arrayAuxiliar= base64.split(',');
                setImageURL(arrayAuxiliar[1]);
            }
        }) 
    }

    const convertirbase64Doc = (archivos) => {
        var filesname = [];
        var listaArchivos = "";
        setDocumentURL(archivos);
        Array.from(archivos).forEach(archivo => {
            filesname.push(archivo.name);
            setListDocuments(filesname);
        })
        filesname.forEach(archivo => { if (listaArchivos===""){
                listaArchivos = listaArchivos + archivo;
            }
            else {
                listaArchivos = listaArchivos+','+archivo;
            } 
        });
        setListArchivos (listaArchivos);
    } 

    const handleUploadDocClick = (document64,namefile) => {
        var body = document64;
        pathParams = {
            //This is where path request params go.
            bucket: 'attach-avhealthfitness',
            folder: auth.user,
            filename: namefile 
          };
          var additionalParams = {headers: {Authorization: auth.tkn}}; 
          apigClientAttachDoc.invokeApi(pathParams, pathTemplateAttach, methodPut, additionalParams, body)
           .then(function(result){
               //This is where you would put a success callback         
           }).catch( function(result){
               //This is where you would put an error callback      
           });       
      }

    const convertirbase64UploadDocs = (archivos) => {
        Array.from(archivos).forEach(archivo => {
            var reader = new FileReader();
            reader.readAsDataURL(archivo);
            reader.onload=function(){
                var arrayAuxiliar=[];
                var base64 = reader.result;
                arrayAuxiliar= base64.split(',');
            //    setDocumentURL(arrayAuxiliar[1]);
                handleUploadDocClick(arrayAuxiliar[1],archivo.name);
            }
        })
    }     

    const handleUploadImgClick = (e) => {
         pathParams = {
            //This is where path request params go.
            bucket: 'attach-avhealthfitness',
            folder: auth.user,
            filename: imgPrevURL.name 
          };
          var body = imageURL;
          var additionalParams = {headers: {Authorization: auth.tkn}};      

          apigClientAttachImg.invokeApi(pathParams, pathTemplateAttach, methodPut, additionalParams, body)
           .then(function(result){
               //This is where you would put a success callback          
           }).catch( function(result){
               //This is where you would put an error callback           
           });            
    }

    const handleUpdateProfessionalProfile = (e) => {
        if (!aceptoText)
        {
            alert(
                // "Debe Aceptar los Terminos y Condiciones."
                "You must accept the Terms and Conditions."
            );
        }
        else
        {
            if (validataData())
            {
            var esNutricionista="N";
            if(esNutriText)
            {
                esNutricionista="Y";
            }
            var esTrainer="N"; 
            if(esTrainingText)
            {
                esTrainer="Y";
            }       
            var body = {
                    professionalId: auth.user,
                    Nutricionista: esNutricionista,
                    Entrenador: esTrainer,
                    FullName: auth.name,           
                    BasicData: {
                                "InfoPersonal": {
                                "M": {
                                    "identificacion": {
                                    "S":data.document
                                    },
                                    "nombre_completo": {
                                    "S": auth.name
                                    },
                                    "fecha_nacimiento": {
                                    "S": fechaText
                                    },
                                    "telefono": {
                                        "S": data.telefono 
                                    },                                  
                                    "direccion": {
                                    "S": dirText 
                                    },
                                    "pais": {
                                        "S": paisText 
                                    },
                                    "departamento": {
                                        "S": deptoText 
                                    },
                                    "municipio": {
                                        "S": muniText 
                                    },
                                    "descr_personal": {
                                    "S": descrText   
                                    },
                                    "foto_perfil_file": {
                                        "S": imgPrevURL.name   
                                    },
                                    "titulos_file": {
                                        "S": listArchivos   
                                    }
                                }
                                },
                                "InfoProfesion": {
                                "M": {
                                    "Nutricionista": {
                                    "S": esNutricionista 
                                    },
                                    "Entrenador": {
                                    "S": esTrainer
                                    },
                                    "exp_laboral": {
                                    "S": laboralText
                                    }
                                }
                                }
                            }                   
                };
                handleUploadImgClick();
                convertirbase64UploadDocs(documentURL);
                var additionalParams = {headers: {Authorization: auth.tkn}};  
                apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
                .then(function(result){
                    //This is where you would put a success callback
                    alert(
                        // "Los Datos del Profesional fueron actualizados correctamente."
                        "The Professional's information was updated successfully."
                    ); 
                    setAuth({user: "", pwd: "", name:"", city:"", documento:"", telefono:"", rol: null, tkn: null, isLogged: false, isEnabled: false });                                                   
                    navigate('/', { replace: true });                           
                }).catch( function(result){
                    //This is where you would put an error callback
                    alert(
                        // "Error al Intentar Actualizar los Datos."
                        "Error while trying to update the information."
                    );        
                });
            }
        }  
    }; 

    const listItems = listDocuments.map((number) => <li>{number}</li>);
    
    return (
        <div className="comp-prof">
            <div className="container-comp-prof">
                <h2>
                    {/* Perfil Profesional */}
                    Professional Profile
                </h2>
                <br/>
                <div className="form-comp-prof">
                    <div>
                        <h4>
                            {/* Dirección */}
                            Address
                        </h4>
                    </div>
                    <input id="direccion"
                     name="direccion" 
                     type="text" 
                    //  placeholder="Dirección de residencia"
                     placeholder="Residential Address"                       
                     value={dirText}
                     onChange={({target: { value }}) => setDirText(value)}                           
                     required 
                     />
                </div>
                <div className="form-comp-prof">
                    <div>
                        <h4>
                            {/* País */}
                            Country
                        </h4>
                    </div>
                    <input id="pais" 
                    name="pais" 
                    type="text" 
                    // placeholder="País de residencia"  
                    placeholder="Country of residence"                     
                    value={paisText}
                    onChange={({target: { value }}) => setPaisText(value)}                          
                    required 
                    />
                </div>
                <div className="form-comp-prof">
                    <div>
                        <h4>
                            {/* Departamento */}
                            State/Province
                        </h4>
                    </div>
                    <input id="departamento" 
                    name="departamento" 
                    type="text" 
                    // placeholder="Departamento de residencia"
                    placeholder="State/Province of residence"
                    required  
                    value={deptoText}
                    onChange={({target: { value }}) => setDeptoText(value)}                        
                     />
                </div>
                <div className="form-comp-prof">
                    <div>
                        <h4>
                            {/* Municipio */}
                            City
                        </h4>
                    </div>
                    <input id="municipio" 
                    name="municipio" 
                    type="text" 
                    // placeholder="Municipio de residencia" 
                    placeholder="City of residence" 
                    required 
                    value={muniText}
                    onChange={({target: { value }}) => setMuniText(value)}                        
                    />
                </div>
                <div className="form-comp-prof">
                    <div>
                        <h4>
                            {/* Fecha de nacimiento */}
                            Date of Birth
                        </h4>
                    </div>
                    <input id="nacimiento" 
                    name="nacimiento" 
                    type="date" 
                    value={fechaText}
                    onChange={({target: { value }}) => setFechaText(value)}                          
                    required 
                    />
                </div>
                <div className="form-comp-prof">
                    <div>
                        <h4>
                           {/* Profesión */}
                           Profession
                        </h4>
                    </div>
                    <div>
                        <h5>
                            {/* Nutricionista */}
                            Nutritionist
                        </h5>
                       <input id="profesion1" 
                        name="profesion"
                        type="checkbox" 
                        checked={esNutriText}
                        onChange={handleChangeNutrCheckBox}                              
                        />
                    </div>
                    <div>
                        <h5>
                            {/* Entrenador */}
                            Trainer
                        </h5>
                        <input id="profesion2" 
                        name="profesion"
                        type="checkbox"
                        checked={esTrainingText}
                        onChange={handleChangeTrainCheckBox}                              
                        />
                    </div>
                </div>
                <div className="form-comp-prof">
                    <div>
                        <h4>
                            {/* Descripción personal */}
                            Personal Description
                        </h4>
                    </div>
                    <textarea id="descripcion"
                    name="descripcion" 
                    maxLength="200" 
                    cols="30" 
                    rows="3"
                    // placeholder="Opcional. Escriba su descripción personal. Máx 200 caracteres"
                    placeholder="Optional. Write your personal description. Max 200 characters."
                    value={descrText}
                    onChange={({target: { value }}) => setDescrText(value)}                          
                    ></textarea>
                </div>
                <br/>
                <div className="form-comp-prof">
                    <div>
                        <h4>
                            {/* Foto personal */}
                            Personal Photo
                        </h4>
                    </div>
                    <input 
                    type="file" 
                    id="foto" 
                    name="foto"
                    accept="image/*" 
                    required
                    onChange={(e)=>convertirbase64(e.target.files)}                       
                    />
                </div>
                <div className="panel-body">
                    {imageURL ? (
                    <img
                        src={URL.createObjectURL(imgPrevURL)}
                        alt="Vista Previa"
                        height="60"
                    />) : null}
                </div>  
                <div className="form-comp-prof">
                    <div>
                        <h4>
                            {/* Título(s) obtenido(s) */}
                            Degree(s) obtained
                        </h4>
                    </div>
                    <input 
                    type="file"
                    accept="application/pdf" 
                    multiple
                    id="titulos" 
                    name="titulos" 
                    required
                    onChange={(e)=>convertirbase64Doc(e.target.files)}                    
                    />
                </div>
                <div>
                    <ul>{listItems}</ul>                    
                </div>
                <div className="form-comp-prof">
                    <div>
                        <h4>
                            {/* Experiencia laboral */}
                            Work Experience
                        </h4>
                        <i>
                            {/* (No es obligatorio tener experiencia laboral) */}
                            (It's not mandatory to have work experience.)
                        </i>
                    </div>
                    <textarea id="experiencia"
                    name="experiencia"
                    maxLength="200"
                    cols="30"
                    rows="3"
                    // placeholder="Escriba su experiencia laboral. Máx 200 caracteres"
                    placeholder="Write your work experience. Max 200 characters."
                    value={laboralText}
                    onChange={({target: { value }}) => setLaboralText(value)}                          
                    ></textarea>
                </div>
                <div className="form-comp-prof">
                        <p>
                            {/* Acepto los Terminos y Condiciones de este sitio. */}
                            I accept the Terms and Conditions of this site.
                        </p>
                        <p>
                        <a
                            href="https://www.varoncare.com/Terminos y Condiciones.pdf"
                            target="_blank"
                            rel="noreferrer">
                                {/* Ver Terminos y Condiciones */}
                                View Terms and Conditions
                        </a>               
                        </p>                        
                        <input type="checkbox"
                        name="checkbox"
                        id="checkbox"
                        checked={aceptoText}
                        onChange={handleChangeCheckBox}                              
                        />
                </div>
                <div>
                    <button type="button" className="btn-comp-prof" onClick={handleUpdateProfessionalProfile}>
                        {/* Enviar */}
                        Submit
                    </button>                                                                                                                                        
                </div>
            </div>
        </div>
    );
}