import { useContext, useState } from 'react';
import AuthContext from "../../../../context/authorizer";
import { useNavigate  } from "react-router-dom";
//import CurrentUserContext from '../../../Home';
import "./logout.css";

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
var poolData = {
         UserPoolId: 'us-east-1_nYrUpgTGj',
         ClientId: '1fbbtser6kh7j5tofdl3uee2h2'
     };
    var userPool = new CognitoUserPool(poolData);

export default function LogOut(data) {
    
    const { auth, setAuth } = useContext(AuthContext);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();       

    const handleLogOutClick = (e) => {

        //       HealthApp.confirm = function () {
               var username = auth.name; //window.location.hash.substring(1);
               var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                   Username: username,
                   Pool: userPool
               });
               cognitoUser.signOut();
               setAuth({user: "", pwd: "", name:"", city:"", documento:"", telefono:"", rol: null, tkn: null, isLogged: false, userData: false });                                                   
               setSuccess(true);                 
               navigate('/', { replace: true });            
               console.log('The link was clicked');          
           };
    if (success){
        return (
            <div //className="contenedor__login-register"
            >
                <button onClick={data.close} className="btn-logout" >
                    {/* CERRAR */}
                    CLOSE
                </button>                 
            </div>

        );
    }
    else{
        return (
            <div>
                <div>
                    {/* Esta seguro que desea cerrar sesión? */}
                    Are you sure you want to log out?
                </div>
                <br></br>                
                <div //className="contenedor__login-register"
                >
                    <button onClick={handleLogOutClick} className="btn-logout" >
                        {/* CERRAR SESION */}
                        LOG OUT
                    </button>                 
                </div>
            </div>
        );
    }
}