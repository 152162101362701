import 'bootstrap/dist/css/bootstrap.min.css';
import "./style-users.css";
import { useState,useContext } from 'react';
import Modal from '../../../Modal';
import RegOffer from '../RegOffer';
import AuthContext from "../../../../context/authorizer";

var apigClientFactory = require('aws-api-gateway-client').default;
var config = {
            invokeUrl: 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN'
          };
    
var apigClient = apigClientFactory.newClient(config);

var pathParams = {
  //This is where path request params go.
  //userId: '1234',
};
// Template syntax follows url-template https://www.npmjs.com/package/url-template
var pathTemplate = '/avhealthregofferresource/getcustomertrainingoffer';
var method = 'POST';

export default function OfferResultItem({OfferId, TrainingArea, JobType,clase_servicio,periodicidad,personas_add,tipo_servicio,vr_oferta}) 
{
    const [isOpen, setIsOpen] = useState(false);
    const [detOfertaText, setDetOfertaText] = useState("");
    const [franjaText, setFranjaText] = useState(""); 
    const { auth } = useContext(AuthContext);                    

    const handleRegOfferClick = (e) => {
        console.log("Clicked");
        var body = {
          UserOfferId: auth.user,          
           TrainingArea: TrainingArea
        }; 
        var additionalParams = {headers: {Authorization: auth.tkn}};  
        apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then(function(result){
            //This is where you would put a success callback
            if (result.headers['content-length'] === '2')
            {
              setIsOpen(true);
            }
            else if (window.confirm(
              // "Ya tiene una Oferta de: "+TrainingArea+" en curso, desea reemplazarla?"
              "You already have an active offer in: "+TrainingArea+", would you like to replace it?"
            ))
            {              
              setIsOpen(true);
            }            
             
        }).catch( function(result){
            //This is where you would put an error callback           
        });   
    //    RegOffer(OfferId);
       }

    return (
      <>
        <Modal
          open={isOpen}
          close={() => setIsOpen(false)}
          openstyle="m-reg-dialog-opened"
          closestyle="m-dialog-closed"
        >
          <div>
            {/* Objetivos y caracteristicas detalladas del Servicio: */}
            Goals and detailed features of the service:
            <br></br>
            <textarea
              id="detoferta"
              // placeholder="Detalles del Servicio..."
              placeholder="Service details..."
              value={detOfertaText}
              onChange={({ target: { value } }) => setDetOfertaText(value)}               
            ></textarea>
          </div>
          <div>
            <div>
              {/* Selecciona tu horario: */}
              Select your schedule:
            </div>
            <input
              id="franjahoraria"
              type="text"
              // placeholder="Franja horaria"
              placeholder="Time slot"
              list="horario"
              value={franjaText}
              onChange={({ target: { value } }) => setFranjaText(value)}                 
            />
            <datalist id="horario">
              <option>05:00 - 12:00</option>
              <option>12:00 - 18:00</option>
              <option>18:00 - 22:00</option>
            </datalist>
          </div>
          <br></br>
          <div>
            {/* UNA VEZ APLIQUES, UN PROFESIONAL TOMARÁ TU SERVICIO Y TE NOTIFICAREMOS. */}
            ONCE YOU APPLY, A PROFESSIONAL WILL TAKE YOUR SERVICE, AND WE WILL NOTIFY YOU.
          </div>
          <div>
            <RegOffer
              results={{
                OfferId,
                TrainingArea,
                JobType: detOfertaText,
                clase_servicio,
                periodicidad,
                personas_add,
                tipo_servicio,
                vr_oferta,
                franja: franjaText,
              }}
              isSearching={TrainingArea}
            />
          </div>
        </Modal>
        {/* <div>
            <button type="button" className="Boton" onClick={handleRegOfferClick}></button>   
        </div>  */}

        <div>
          <div className="row row-cols-1 row-cols-md-3 mb-4 text-center justify-content-center  d-flex align-items-center align-self-center ">
            <div className="col">
              <button
                type="button"
                className="boton-user"
                onClick={handleRegOfferClick}
              >
                <div className="card-user">
                  <div className="card-header-user py-3">
                    <h1 className="my-0 fw-normal">
                      <b>
                        {clase_servicio} {personas_add}
                      </b>
                    </h1>
                  </div>
                  <div className="card-body-user">
                    <h1 className="card-title pricing-card-title">
                      ${vr_oferta} USD
                    </h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>
                        <h2>{JobType}</h2>
                      </li>
                      <li>
                        <h2>{periodicidad}</h2>
                      </li>
                      <li>
                        <h2>{tipo_servicio}</h2>
                      </li>
                    </ul>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </>
    );
}