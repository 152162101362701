import "./style-footer.css";
import {useState,useContext } from 'react'
import AuthContext from "../../../../context/authorizer";
import ViewModal from './modal'
import SignUp from "../SignUp";
//import UilWhatsapp from '@iconscout/react-unicons/icons/uil-whatsapp';
//import UilFacebook from '@iconscout/react-unicons/icons/uil-facebook-f';
//import UilInstagram from '@iconscout/react-unicons/icons/uil-instagram';

export default function Footer() {
    const [isOpen, setIsOpen] = useState(false);
    const { auth } = useContext(AuthContext);   
    var divBox;     

    const handleClickOn = (e) => {
        setIsOpen(true);
       }
       if (auth.isLogged) {
        divBox = <div><br></br></div>;
      }else
      {
        divBox = <div><button onClick={handleClickOn} className="boton-registro">
          {/* REGISTRATE */}
          SIGN UP
        </button><br></br></div>;
      };  
      
return (
  <>
    <ViewModal open={isOpen} close={() => setIsOpen(false)}>
      <SignUp />
    </ViewModal>
    <div>
      <div className="salud_deporte">
        <h2>
          {/* SALUD Y DEPORTE DESDE LA COMODIDAD DE TU HOGAR */}
          HEALTH AND FITNESS FROM THE COMFORT OF YOUR HOME
        </h2>
      </div>
      <div className="estilo_vida">
        <h2>
          {/* CREA TU ESTILO DE VIDA */}
          CREATE YOUR LIFESTYLE
        </h2>
        {divBox}
      </div>
    </div>
    {/* <div className="main-nav nav navbar-nav navbar-right">
      <div className="row">
        <div className="wrapper" id="redes">
          <div className="icon facebook">
            <div className="tooltip">Facebook</div>
            <a
              className="link"
              href="https://www.facebook.com/Avhealthfitness-105070241862037"
              target="_blank"
            >
              <span>
                <i className="fab fa-facebook-f"></i>
              </span>
            </a>
          </div>
          <div className="icon instagram">
            <div className="tooltip">Instagram</div>
            <a
              id="instagram"
              style="text-decoration: none"
              href="https://www.instagram.com/avhealth.fitness/"
              target="_blank"
            >
              <span>
                <i class="fab fa-instagram"></i>
              </span>
            </a>
          </div>
          <div className="icon whatsapp">
            <div className="tooltip">Whatsapp</div>
            <a
              id="whatsapp"
              style="text-decoration: none"
              href="https://wa.me/573235133787"
              target="_blank"
            >
              <span>
                <i className="fab fa-whatsapp"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div> */}
    <div className="container">
      <footer className="d-flex flex-wrap justify-content-center align-items-center py-3 my-4 border-top">
        <div className="col-md-4 d-flex align-items-center">
          <span className="mb-3 mb-md-0 text-muted">
            {/* Varon Care - Todos los derechos reservados. */}
            Varon Care - All Rights Reserved. 
            &copy;
            {new Date().getFullYear()}
          </span>
        </div>

        {/* <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="ms-3">
            <a
              className="text-muted"
              href="https://wa.me/573235133787"
              target="_blank"
              rel="noreferrer"
              alt="WhatsAPP"
            >
              <i>
                <UilWhatsapp />
              </i>
            </a>
          </li>
          <li className="ms-3">
            <a
              className="text-muted"
              href="https://www.facebook.com/Avhealthfitness-105070241862037"
              target="_blank"
              rel="noreferrer"
              alt="Facebook"
            >
              <i>
                <UilFacebook />
              </i>
            </a>
          </li>
          <li className="ms-3">
            <a
              className="text-muted"
              href="https://www.instagram.com/avhealth.care/"
              target="_blank"
              rel="noreferrer"
              alt="Instagram"
            >
              <i>
                <UilInstagram />
              </i>
            </a>
          </li>
        </ul> */}
      </footer>
    </div>
  </>
);
}