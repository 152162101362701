import OfferResultsItem from "./OfferResultItem";
import "./style-admin.css";
import Loader from "../../../Loader";

export default function OfferPendResults({results, isSearching}) {

        if (isSearching) {
            return(<Loader/>);
        }
        else
        {
            return (
            <>
            <div className="ofer-pend-validar">
            <div className="ofer-pend-validar-content">
                {/* OFERTAS PENDIENTES DE VALIDAR */}
                PENDING OFFERS TO VALIDATE
            </div>
            <div>
                {results?.filter((value) => value.OfferStatus.includes("in-progress")).map((value)=>
                <OfferResultsItem key={value.ProfessionalId+"|"+value.Timestamp} {...value} />)} 
            </div>
            </div>
            </>
            );  
        }
}