import { useState, useRef } from "react";
import { useNavigate  } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./otp.css";

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
var poolData = {
         UserPoolId: 'us-east-1_nYrUpgTGj',
         ClientId: '1fbbtser6kh7j5tofdl3uee2h2'
     };
    var userPool = new CognitoUserPool(poolData);

export default function Confirm() {
    const [codeConfirm, setCodeConfirm] = useState(["","","","","",""]);         
    const navigate = useNavigate();     
    const text1Ref = useRef(null);
    const text2Ref = useRef(null);          
    const text3Ref = useRef(null);          
    const text4Ref = useRef(null);          
    const text5Ref = useRef(null);
    const buttonRef = useRef(null);                 
    const { id } = useParams();

    const handleCodeChange = (val,pos) => {
        var code = codeConfirm;
        if (pos===0)
        {
            text1Ref.current.focus(); 
        }
        if (pos===1)
        {
            text2Ref.current.focus();                  
        }
        if (pos===2)
        {
            text3Ref.current.focus();                  
        }
        if (pos===3)
        {
            text4Ref.current.focus();                  
        }
        if (pos===4)
        {
            text5Ref.current.focus();                  
        }
        if (pos===5)        
        {
            buttonRef.current.focus();                  
        }        
        code[pos] = val;
        code[pos+1] = "";
        setCodeConfirm(code);
    }

    const inicializaCode = (e) => {
        setCodeConfirm(["","","","","",""]);       
    }    

    const handleConfirmClick = (e) => {
 //       HealthApp.confirm = function () {
        var username = id; //window.location.hash.substring(1);
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: username,
            Pool: userPool
        });
        
        var code = codeConfirm[0] + codeConfirm[1] + codeConfirm[2] + codeConfirm[3] + codeConfirm[4] + codeConfirm[5];
        cognitoUser.confirmRegistration(code, true, function (err, results) {
            if (err) {
                alert(err);
            } else {
                alert(
                    // "Correo Verificado correctamente. Ahora puede iniciar Sesión"
                    "Email successfully verified. You can now log in."
                );              
            //    window.location = '/index.html';
            }
        });
   //     };
        navigate('/', { replace: true });     
        e.preventDefault();
        console.log('The link was clicked');          
    };
    const handleResendClick = (e) => {
    //    HealthApp.resend = function () {
        var username = id; //window.location.hash.substring(1);
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser({
                Username: username,
                Pool: userPool
        });
        cognitoUser.resendConfirmationCode(function (err) {
            if (err) {
                alert(err);
            }
        })
    //    };
        e.preventDefault();
        console.log('The link was clicked');      
    };
    return (
    <>
    <div className= "cod-confirm">
        <div className="container">
            <label >
                <h2>
                    <b>
                        {/* Código de confirmación */}
                        Confirmation code
                    </b>
                </h2>
            </label>
            <div className="code-container">
            <input type="number"
            imputmode="numeric" 
            className="code" 
            placeholder="0" 
            min="0" 
            max="9"
            required 
            onClick={inicializaCode}
            value={codeConfirm[0]} 
            onChange={({target: { value }}) => handleCodeChange(value,0)}  
            />
            <input type="number"
            imputmode="numeric" 
            ref={text1Ref}
            id="campo2"
            className="code" 
            placeholder="0" 
            min="0" 
            max="9"
            onClick={inicializaCode}            
            value={codeConfirm[1]}
            onChange={({target: { value }}) => handleCodeChange(value,1)}                
            />
            <input type="number"
            imputmode="numeric"
            ref={text2Ref}             
            className="code" 
            placeholder="0" 
            min="0" 
            max="9" 
            required 
            onClick={inicializaCode}            
            value={codeConfirm[2]}
            onChange={({target: { value }}) => handleCodeChange(value,2)}             
            />
            <input type="number"
            imputmode="numeric" 
            ref={text3Ref}            
            className="code" 
            placeholder="0" 
            min="0" 
            max="9" 
            required
            onClick={inicializaCode}            
            value={codeConfirm[3]}
            onChange={({target: { value }}) => handleCodeChange(value,3)}              
            />
            <input type="number"
            imputmode="numeric"
            ref={text4Ref}             
            className="code" 
            placeholder="0" 
            min="0" 
            max="9" 
            required 
            onClick={inicializaCode}            
            value={codeConfirm[4]}
            onChange={({target: { value }}) => handleCodeChange(value,4)}               
            />
            <input type="number"
            imputmode="numeric" 
            ref={text5Ref}            
            className="code" 
            placeholder="0" 
            min="0" 
            max="9" 
            required 
            onClick={inicializaCode}            
            value={codeConfirm[5]}
            onChange={({target: { value }}) => handleCodeChange(value,5)}               
            />                                    
            </div>
            <button ref={buttonRef} onClick={handleConfirmClick} className="btn-primary">
                {/* CONFIRMAR */}
                CONFIRM
            </button>
            <div>
                <button onClick={handleResendClick} className="btn-primary">
                    {/* REENVIAR */}
                    RESEND
                </button>
            </div>
        </div>
    </div>  
    </>              
    );
}