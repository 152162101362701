import { useContext,useEffect,useState } from "react";
import AuthContext from '../../context/authorizer';
import apiCall from "../../api";

import OfferPendResults from "./components/ShowPendOffers";
//import NavMenu from "../Home/components/NavMenu";
import NavBar from "../Home/components/NavBar";

export default function Admin() {
    const [isLoading,setIsLoading] = useState(false);
    const [data,setData] = useState([]);    
    const { auth } = useContext(AuthContext);    
    let headData = new Headers();    

    headData.append('Content-Type', 'application/json');
    headData.append('Accept', 'application/json');
    headData.append('Authorization', auth.tkn );
    headData.append('Origin','*');    
     useEffect(() => {
        const getOffers = async () => {
            try {
                setIsLoading(true);
                const response = await apiCall(
                {
                    url:'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN/avhealthofferresource',
                    method: "post",
                    mode: "cors",
                    headers: headData
                });
           //     const data = await response.json();
                setData(response);
                setIsLoading(false);                
            } catch(err) {
                console.error(err);
            }
        };
        getOffers().catch(null);
    }, []); 
  
    return (
        <div>
        {/* <NavMenu color={"bm-menu-violet"}/> */}
        <OfferPendResults results={data} isSearching = {isLoading} />
        <NavBar/>        
        </div>              
    );
}