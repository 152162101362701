import { useState, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/authorizer";
import "./style-auth.css";

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
var CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
var poolData = {
         UserPoolId: 'us-east-1_nYrUpgTGj',
         ClientId: '1fbbtser6kh7j5tofdl3uee2h2'
     };
    var userPool = new CognitoUserPool(poolData);

var apigClientFactory = require('aws-api-gateway-client').default;
var config = {
            invokeUrl: 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN'
          };
var apigClient = apigClientFactory.newClient(config);

var pathParams = {
  //This is where path request params go.
  //userId: '1234',
};
// Template syntax follows url-template https://www.npmjs.com/package/url-template
var pathTemplate = '/avhealthprofprofileresource/{professionalId}';  
var method = 'GET';


export default function Authenticate({children, onLogin }) {
    const { auth, setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    
    const [tokenValue, setTokenValue] = useState("");     

    // const [items, setItems] = useState([]);

    // useEffect(() => {
    //   localStorage.setItem('userData',items);
    // }, [items]);    
  
    //const {showAlert} = useContext(AuthContext);  

/* useEffect(() => {
     showAlert();
 }, []); */
  //  const [errMsg, setErrMsg] = useState('');
    const [userText, setUserText] = useState("");
    const [pwdText, setPwdText] = useState(""); 

 /*   useEffect(() => {
      userRef.current.focus();
    }, []); */

 /*   useEffect(() => {
      setErrMsg('');
    }, [userText, pwdText]); */

    const handleLoginClick = (e) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        //headers.append('Authorization', token);
        headers.append('Origin','*');
        const url = 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN/avhealthuserprofileresource/';
       
        var authenticationData = {
            Username: userText,
            Password: pwdText
        };
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);        
        var userData = {
            Username: userText,
            Pool: userPool
        };
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function () {
                var cognitoUser = userPool.getCurrentUser();
                if (cognitoUser !== null) {
                    cognitoUser.getSession(function (err, session) {
                        if (err) {
                            window.location = '/';
                        }
                      var token = session.getIdToken().getJwtToken();
                      setTokenValue(session.getIdToken().getJwtToken());
                      var usuario = session.getIdToken().payload['cognito:groups'];
                      var fname = session.getIdToken().payload['custom:fullname'];
                      var cityname = session.getIdToken().payload['custom:city'];
                      var nrodoc = session.getIdToken().payload['custom:documento'];
                      var nrotel = session.getIdToken().payload['custom:telefono'];                        
                      var grupo = usuario[0];
                      setAuth({ user: userText, pwd: pwdText, name: fname, city:cityname , documento: nrodoc , telefono: nrotel , rol: grupo, tkn: token, isLogged: true });
                 //     setAuth({ user: userText, pwd: pwdText, name: fname, city:cityname , documento: nrodoc , telefono: nrotel , rol: grupo, tkn: token, isLogged: true, isEnabled: true });                      
                      setUserText('');
                      setPwdText('');
                    if(grupo === 'Admin'){
                      setAuth({ user: userText, pwd: pwdText, name: fname, city:cityname , documento: nrodoc , telefono: nrotel , rol: grupo, tkn: token, isLogged: true, isEnabled: true });                    
                    }
                    if (grupo === 'Customer') 
                    {
                      var url2 = url + userText;
                      fetch(url2, {
                        method: 'get',
                        mode: 'cors',
                       // credentials: 'include',  
                      //  mode: 'no-cors',
                        headers
                      })
                      .then(function(response) {
                        var contentType = response.headers.get("content-type");
                        var contentLength = response.headers.get("content-length");                        
                        if (contentLength === "2"){
                    //      setItems('false');
                          navigate('/updateprofile', { replace: true });    
                        } 
                        if (contentLength > 2){
                     //     localStorage.setItem('userData','true');
                          setAuth({ user: userText, pwd: pwdText, name: fname, city:cityname , documento: nrodoc , telefono: nrotel , rol: grupo, tkn: token, isLogged: true, isEnabled: true });                      
                          //console.log(contentLength);                                                     
                        }
                                            
                        if(contentType && contentType.indexOf("application/json") !== -1) {
                          return response.json().then(function(json) {
                            // process your JSON further
                          });
                        } else {
                          console.log("Oops, we haven't got JSON!");
                        }
                      });
                    }
                    if(grupo === 'Professional'){
                      pathParams = {professionalId: userText};
                      //    var additionalParams = {headers: {Authorization: null}};
                     // var additionalParams = {headers: {}}; 
                      var additionalParams = {headers: {Authorization:token}}; 

                      apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, null)
                      .then(function(result){
                          //This is where you would put a success callback
                          //console.log(result);            
                          if (result.headers['content-length'] === '2')
                          {
                            navigate('/updateprofile', { replace: true });    
                          }
                          else
                          {
                            setAuth({ user: userText, pwd: pwdText, name: fname, city:cityname , documento: nrodoc , telefono: nrotel , rol: grupo, tkn: token, isLogged: true, isEnabled: true });                            
                          }
                      }).catch( function(result){
                          //This is where you would put an error callback
                          //console.log(result);            
                      }); 
                    } 
                  });                                                                       
                }
         },
            onFailure: function (err) {
              //console.log(err); 
              if ( err.name === "NotAuthorizedException") 
              {
                alert(
                  // "Usuario y/o Contraseña incorrectos."
                  "Invalid username or password."
                );
              }else if ( err.name === "UserNotConfirmedException") 
              {
                navigate('/confirm/'+userText, { replace: true });
              }                
              else
              {
               alert(err.message);
              }               
              }
            });        
        e.preventDefault();
        console.log('The link was clicked');
    }
    if (auth.isLogged) {
      return (
        <div><br></br></div>
      );
    }
    else{
    return (
      <div className="contenedor__login-register">
        <form
          action="" //</div>className="formulario__login"
        >
          <input
            id="username"
            type="email"
            // placeholder="Correo Electrónico"
            placeholder="Email"
            value={userText}
            onChange={({ target: { value } }) => setUserText(value)}
          />
          <input
            id="password"
            type="password"
            // placeholder="Contraseña"
            placeholder="Password"
            value={pwdText}
            onChange={({ target: { value } }) => setPwdText(value)}
          />
          <div className='chgpwd'>
            <Link to="changepwd">
              {/* Cambiar contraseña */}
              Change password
            </Link>
          </div>
          <div className='fgtpwd'>
            <Link to="forgotpwd">
              {/* Recuperar contraseña */}
              Forgot password
            </Link>
          </div>
          <div>
            <button
              onClick={handleLoginClick}
              disabled={!userText.length}
              className="boton-entrar">
                {/* INICIAR */}
                SIGN IN
            </button>
          </div>
        </form>
      </div>
    );
  }
}