import OfferResultsItem from "./OfferResultItem";
import { useState } from 'react';
import "./style-users.css";
import nutricion from "./img/nutricion.webp";
import Modal from "../../../Modal";

export default function OfferResults({results, isSearching, tiposervicio}) {
    const [offerText, setOfferText] = useState(tiposervicio);
    const [detOfertaText, setDetOfertaText] = useState("");
    const [isOpen, setIsOpen] = useState(false);        
    let divBox;

    if (offerText === "NUTRICION") {
        divBox = <div className="showcase" >
            <img src={nutricion} alt="Nutricion" className="offer-nutri"></img>

            </div>; 
    };
    if (offerText === "PERSONAL_TRAINER") {
        divBox = <div className="offer-train" ></div>; 
    };
    if (offerText === "PAQUETE_INTEGRAL") {
        divBox = <div className="offer-paq" ></div>; 
    };  
    
    const handleClickOnWhatsApp = (e) => {
        window.location.href = 
        // 'https://wa.me/573235133787?text=Hola!%20estoy%20interesad@%20en%20un%20servicio%20personalizado';
        'https://wa.me/573235133787?text=Hi!%20I%20am%20interested%20in%20a%20personalized%20service';
       }    
    
    
return (
    <>
    {divBox}
    <div>
    <label htmlFor="Offers"></label>
        <select className="OfferType" id="offertype-user"
        value={offerText}
        onChange={({target: { value }}) => setOfferText(value.toString())}          
        >
        {/* <option value="NUTRICION"> */}
          {/* NUTRICIÓN */}
          {/* NUTRITION */}
        {/* </option> */}
        {/* <option value="PERSONAL_TRAINER"> */}
          {/* PERSONAL TRAINER */}
        {/* </option> */}
        <option value="PAQUETE_INTEGRAL">
          {/* PAQUETE INTEGRAL */}
          FULL PACKAGE
        </option>
    </select>
    </div>
    <Modal
          open={isOpen}
          close={() => setIsOpen(false)}
          openstyle="m-reg-dialog-opened"
          closestyle="m-dialog-closed"
        >
          <div>
            {/* DESCRIBENOS DE MANERA RAPIDA EL SERVICIO QUE BUSCAS */}
            DESCRIBE QUICKLY THE SERVICE YOU ARE LOOKING FOR
            <br></br>
            <textarea
              id="detoferta"
              // placeholder="Detalles del Servicio..."
              placeholder="Service Details..."
              value={detOfertaText}
              onChange={({ target: { value } }) => setDetOfertaText(value)}               
            ></textarea>
          </div>
          <br></br>
          <div>
            <button type="button" className="ofer-personalizada" onClick={handleClickOnWhatsApp}>
              {/* ENVIAR */}
              SUBMIT
            </button>
          </div>
        </Modal>    
    <button type="button" className="ofer-personalizada" onClick={handleClickOnWhatsApp}>
      {/* PERSONALIZA TU SERVICIO AQUÍ */}
      CUSTOMIZE YOUR SERVICE HERE
    </button>
    <div>
        {isSearching && 
        <p>
          {/* No hay datos */}
          No data
        </p>}
        {results?.filter((value) => value.TrainingArea.includes(offerText)).map((value)=>
        <OfferResultsItem key={value.OfferId} {...value} />)}
    </div>
    </>
);
}