import { useState, useContext } from 'react';
import AuthContext from "../../../../context/authorizer";
import { useNavigate  } from "react-router-dom";
import "./val-prof.css";
import AttachmentList from './AttachmentList';

var apigClientFactory = require('aws-api-gateway-client').default;

var config = {
            invokeUrl: 'https://1lyk88ihwg.execute-api.us-east-1.amazonaws.com/PDN'
          };

          var configAttachImg = {
    invokeUrl: 'https://r1ad1i0bo1.execute-api.us-east-1.amazonaws.com/PDN/',
    defaultContentType: 'image/jpeg'
  };
  
var apigClientAttachImg = apigClientFactory.newClient(configAttachImg);
var apigClient = apigClientFactory.newClient(config);

var pathParams = {
  //This is where path request params go.
  //userId: '1234',
};
var pathParamsAttach = {
    //This is where path request params go.
    //userId: '1234',
  };
// Template syntax follows url-template https://www.npmjs.com/package/url-template
var pathTemplateAttach = '/avhealthattachs3folderresource/{bucket}/{folder}/{filename}';  
var pathTemplate = '/avvalidateprofprofileresource';  
var method = 'POST';
var methodGet = 'GET';  

export default function ProfProfileDetail(data) {
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate(); 
    const [imgURL,setImgURL]=useState(null);
    var buttonImg;
    
    const convertirbase64Text = (archivo) => {
        const linkSource = `data:image/jpeg;base64,${archivo}`;
        var url = linkSource;
        fetch(url)
             .then((response) => response.blob())
             .then((data) =>  setImgURL(data));
        }       
    
        const handleDownloadImgClick = (e) => {
          //  var body = documentURL;
            
            pathParamsAttach = {
                //This is where path request params go.
                bucket: 'attach-avhealthfitness',
                folder: data.result.data.Item.professionalId.S,
                filename: data.result.data.Item.BasicData.M.InfoPersonal.M.foto_perfil_file.S 
              };
              var additionalParams = {headers: {Authorization: auth.tkn}}; 
              apigClientAttachImg.invokeApi(pathParamsAttach, pathTemplateAttach, methodGet, additionalParams, null)
               .then(function(result){
                   //This is where you would put a success callback 
                   convertirbase64Text(result.data);          
               }).catch( function(result){
                   //This is where you would put an error callback         
               });       
          }      
    
    const handleValidateUserClick = (e) => {
        var additionalParams = {headers: {Authorization:auth.tkn}}; 
        var body = {
                ProfessionalId: data.result.data.Item.professionalId.S
            };
        apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then(function(result){
        //This is where you would put a success callback
        alert(
            // "El profesional fue Validado correctamente."
            "The professional was successfully validated."
        );
        navigate('/admin-prof-pend-val', { replace: true });   

    }).catch( function(result){
        //This is where you would put an error callback        
    }); 
    }    
  
    var arrayFiles=[];
    var fileString = data.result.data.Item.BasicData.M.InfoPersonal.M.titulos_file.S;
    arrayFiles= fileString.split(',');
    
    if (imgURL!==null)
    {
        buttonImg = <button type="button" className="btn-comp-prof" onClick={(e) =>setImgURL(null)}>
            {/* Cerrar */}
            Close
        </button>;

    }else{
        buttonImg = <button type="button" className="btn-comp-prof" onClick={handleDownloadImgClick}>
            {/* Ver */}
            View
        </button>;
    } 

    return (
        <>
            <div className="card-validarprofesional">
                <div className="container-validarprofesional">
                <h1>
                    {/* Profesional a validar */}
                    Professional to validate
                </h1>
                <br/>
                <div>
                    <h2>
                        {/* Nombre completo */}
                        Full Name
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoPersonal.M.nombre_completo.S}</h4>
                </div>
                <h2>
                    {/* Usuario */}
                    User
                </h2>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.professionalId.S}</h4>
                </div>
                <div>
                    <h2>
                        {/* Identificación */}
                        Identification
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoPersonal.M.identificacion.S}</h4>
                </div>
                <div>
                    <h2>
                        {/* Fecha de nacimiento */}
                        Date of Birth
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoPersonal.M.fecha_nacimiento.S}</h4>
                </div>
                <div>
                    <h2>
                        {/* Descripción personal */}
                        Personal Description
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoPersonal.M.descr_personal.S}</h4>
                </div>
                <div>
                    <h2>
                        {/* País */}
                        Country
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoPersonal.M.pais.S}</h4>
                </div>
                <div>
                    <h2>
                        {/* Departamento */}
                        State/Province
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoPersonal.M.departamento.S}</h4>
                </div>
                <div>
                    <h2>
                        {/* Municipio */}
                        City
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoPersonal.M.municipio.S}</h4>
                </div>
                <div>
                    <h2>
                        {/* Dirección */}
                        Address
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoPersonal.M.direccion.S}</h4>
                </div>
                <div>
                    <h2>
                        {/* Teléfono */}
                        Telephone
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoPersonal.M.telefono.S}</h4>
                </div>              
                <div>
                    <h2>
                        {/* Entrenador */}
                        Trainer
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoProfesion.M.Entrenador.S}</h4>
                </div>
                <div>
                    <h2>
                        {/* Nutricionista */}
                        Nutritionist
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoProfesion.M.Nutricionista.S}</h4>
                </div>
                <div>
                    <h2>
                        {/* Experiencia laboral */}
                        Work Experience
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoProfesion.M.exp_laboral.S}</h4>
                </div>
                <div>
                    <h2>
                        {/* Foto de perfil */}
                        Profile Picture
                    </h2>
                </div>
                <div className="form-validar-prof">
                    <h4>{data.result.data.Item.BasicData.M.InfoPersonal.M.foto_perfil_file.S}</h4>
                </div>
                <div>
                    {imgURL ? (
                    <img
                        src={URL.createObjectURL(imgURL)}
                        alt="Vista Previa"
                        height="128"
                    />) : null}
                </div>                 
                <div>             
                    {buttonImg}
				</div>
                <div>
                    <h2>
                        {/* Títulos */}
                        Degrees
                    </h2>
                </div>
                <div>
                    {arrayFiles.map((file)=>
                    <AttachmentList key={file} value={file} user={data.result.data.Item.professionalId.S} />)} 
                </div>
                <div>
                    <button type="button" className="btn-validar-profesional" onClick={handleValidateUserClick}>
                        {/* Validar */}
                        Validate
                    </button>
                </div>
                </div>
            </div>
        </>
);
}