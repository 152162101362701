import 'bootstrap/dist/css/bootstrap.min.css';
//import "./style-admin.css";
//import {useState} from 'react';
//import Modal from '../../../Modal';
import { useNavigate  } from "react-router-dom";

//import UpdateOffer from '../UpdateOffer';

export default function ProfResultItem({ProfessionalId, ProfessionalName}) 
{
    const navigate = useNavigate();       

    const handleUpdateOfferClick = (e) => {
        console.log("Clicked");
        navigate('/detail/'+ProfessionalId, { replace: true });        
       }

    return (
        <div>
            <div >
            <h2>
                {/* Usuario */}
                User
            </h2>
            <h4>{ProfessionalId}</h4>
            <br></br>
            <h2>
                {/* Nombre Profesional */}
                Professional Name
            </h2>
            <h4>{ProfessionalName}</h4>
            </div>
            <br></br>
            <button type="button" className="btn-detalles-vp" onClick={handleUpdateOfferClick}>
                {/* Detalles */}
                Details
            </button>
       </div>
    );
}